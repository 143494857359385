<!-- eslint-disable vue/no-v-html -->
<template>
  <main
    class="card-content prose relative z-20 w-full max-w-none font-light prose-p:font-light"
    :class="{
      '!font-light [&>&]:w-full': !isPdfPage,
      'text-card-primary prose-a:text-card-primary': !textColor
    }"
    :style="style"
    v-html="formatContent(content)"
  ></main>
</template>

<script setup lang="ts">
import type { CardType } from '@autobid/ui/types/components/CardFamily'
import { useCkeditor } from '@autobid/ui/composables/useCkeditor'
import { computed, inject } from 'vue'

const { content, textColor } = inject('card') as CardType
const isPdfPage = inject('isPdfPage', false)
const { formatContent } = useCkeditor()

const style = computed(() => {
  let result: Record<string, string> = {}

  if (textColor) {
    result = { color: textColor, '--tw-prose-links': textColor }
  }

  if (isPdfPage) {
    result.width = '97%'
  }

  return result
})
</script>

<style lang="scss">
.card-content {
  .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 0 auto;
    min-width: 50px;

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      min-width: 100%;
    }
  }

  .image-style-side {
    float: right;
    margin-left: 4px;
    max-width: 50%;
  }
}
</style>
